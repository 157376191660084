import { useState, useEffect } from "react";
import { Card } from "primereact/card";
import { MoneyValue } from "../components/MoneyValue";
import { FinancialStatus } from "../interfaces";
import client from "../client";

export default function DashboardPage() {
  const [financialStatus, setFinancialStatus] = useState<FinancialStatus>();

  function loadFinancialStatus() {
    client.get("/api/v1/timetracking/financial-status/").then((response) => {
      setFinancialStatus(response.data as FinancialStatus);
      console.log("Data:", response.data);
    });
  }

  useEffect(() => {
    loadFinancialStatus();
  }, []);

  return (
    <Card title="Financial Status" className="m-4">
      <div>
        <label>Income surplus:</label>
        <MoneyValue value={financialStatus?.income_surplus} />
      </div>
      <div>
        <label>Income today:</label>
        <MoneyValue value={financialStatus?.income_today} />
      </div>
      <div>
        <label>Daily income need:</label>
        <MoneyValue value={financialStatus?.income_need_daily} />
      </div>
    </Card>
  );
}
