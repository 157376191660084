import { atom } from "jotai";
import { atomWithStorage } from "jotai/utils";
import { Customer, Token, Errors } from "./interfaces";

export const tokenAtom = atomWithStorage<Token>("token", {
  access: "",
  refresh: "",
} as Token);
export const customersAtom = atom<Customer[]>([]);

export const errorsAtom = atom<Errors>({
  messages: [],
  field_messages: {},
} as Errors);
