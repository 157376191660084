import { Money } from "../interfaces";
import numbro from "numbro";

// Function to force the formatting of a number to Icelandic. Apparently
// TypeScript is perfectly incapable of this simple task so we'll shitmix it
// for now, hoping there's a better solution (that actually **works**) some
// time in the distant future.
export function formatAmount(num: number | undefined) {
  if (num == undefined) {
    return "";
  }
  var str = numbro(num).format({ thousandSeparated: true });
  str = str.replace(/,/g, "C");
  str = str.replace(/\./, ",");
  str = str.replace("C", ".");
  return str;
}

interface MoneyValueProps {
  value: Money | undefined;
}

export function MoneyValue(props: MoneyValueProps) {
  if (props.value == undefined) {
    return <></>;
  }
  return <>{formatAmount(props.value.amount) + " " + props.value.currency}</>;
}
