import { Message } from "primereact/message";
import { useAtom } from "jotai";
import { errorsAtom } from "../state";

export function Error() {
  const [errors] = useAtom(errorsAtom);

  return (
    <>
      {errors.messages.map((message: string, index: number) => (
        <Message key={index} severity="error" text={message} />
      ))}
    </>
  );
}
