import { useRef } from "react";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import { Card } from "primereact/card";
import { Error } from "../components/Error";

interface LoginPageProps {
  onLogin(email: string, password: string): void;
}

function LoginPage(props: LoginPageProps) {
  const email = useRef<string>("");
  const password = useRef<string>("");

  function onSubmit(e: React.SyntheticEvent) {
    e.preventDefault();
    props.onLogin(email.current, password.current);
  }

  return (
    <form onSubmit={onSubmit}>
      <Card title="Sign in" className="p-4">
        <div className="flex flex-column gap-4">
          <InputText
            placeholder="Email"
            type="email"
            autoFocus
            onChange={(e) => {
              email.current = e.target.value;
            }}
          />
          <InputText
            placeholder="Password"
            type="password"
            onChange={(e) => {
              password.current = e.target.value;
            }}
          />
          <Error />
          <Button>Login</Button>
        </div>
      </Card>
    </form>
  );
}

export default LoginPage;
