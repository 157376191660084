import { useEffect, useState } from "react";
import {
  useNavigate,
  useLocation,
  Navigate,
  Routes,
  Route,
} from "react-router-dom";
import { AxiosResponse, AxiosError } from "axios";
import { useAtom } from "jotai";
import { RESET } from "jotai/utils";
import "primereact/resources/themes/lara-light-indigo/theme.css";
import "primereact/resources/primereact.min.css";
import "primeflex/primeflex.css";
import "primeicons/primeicons.css";
import DashboardPage from "./pages/DashboardPage";
import CustomersPage from "./pages/CustomersPage";
import LoginPage from "./pages/LoginPage";
import { tokenAtom, errorsAtom } from "./state";
import client from "./client";
import { Errors } from "./interfaces";
import AppLayout from "./layouts/AppLayout";
import IntermediateLayout from "./layouts/IntermediateLayout";
import "./App.css";

function App() {
  const navigate = useNavigate();
  const location = useLocation();
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [token, setToken] = useAtom(tokenAtom);
  const [errors, setErrors] = useAtom(errorsAtom);

  useEffect(() => {
    if (token.access.length) {
      client.defaults.headers.common[
        "Authorization"
      ] = `Bearer ${token.access}`;
    }
    setIsLoading(false);
  }, [token]);

  function login(email: string, password: string) {
    client
      .post("/api/v1/login/", {
        email,
        password,
      })
      .then((response: AxiosResponse) => {
        let new_token = {
          access: response.data.access,
          refresh: response.data.refresh,
        };
        setToken(new_token);
        navigate("/");
      })
      .catch((err: AxiosError) => {
        if (err.response?.status == 400) {
          // Assume we know how to handle the error.
          setErrors(err.response.data as Errors);
        } else {
          // Otherwise just dump it to the console.
          console.error("Error received:", err);
        }
      });
  }

  function logout() {
    setToken(RESET);
  }

  if (!token.access && location.pathname !== "/login") {
    return <Navigate to="/login" replace={true} />;
  }

  if (isLoading) {
    return (
      <div className="flex min-h-screen align-items-center justify-content-center">
        <span className="text-6xl">Loading...</span>
      </div>
    );
  }

  return (
    <Routes>
      <Route path="/" element={<AppLayout onLogout={logout} />}>
        <Route index element={<DashboardPage />} />
        <Route path="/customers" element={<CustomersPage />} />
      </Route>
      <Route path="/" element={<IntermediateLayout />}>
        <Route path="/login" element={<LoginPage onLogin={login} />} />
      </Route>
    </Routes>
  );
}

export default App;
