import { Button } from "primereact/button";
import { Menubar } from "primereact/menubar";
import { Outlet, useNavigate } from "react-router-dom";
import { useAtom } from "jotai";
import { tokenAtom } from "../state";
import constants from "../constants";

interface AppLayoutProps {
  onLogout(): void;
}

export default function AppLayout(props: AppLayoutProps) {
  const navigate = useNavigate();
  const [token, setToken] = useAtom(tokenAtom);

  const items = [
    {
      label: "Dashboard",
      command: () => navigate("/"),
    },
    {
      label: "Customers",
      command: () => navigate("/customers"),
    },
  ];

  return (
    <>
      <Menubar
        model={items}
        start={<span className="text-xl">{constants.app_name}</span>}
        end={
          !token.access ? (
            <Button onClick={() => navigate("/login")}>Login</Button>
          ) : (
            <Button onClick={props.onLogout}>Logout</Button>
          )
        }
      />
      <div className="flex">
        <Outlet />
      </div>
    </>
  );
}
