import { Component } from "react";

type ErrorState = {
  hasError: boolean;
  errorType: any;
  errorInfo: any;
};

class ErrorBoundary extends Component<any, ErrorState> {
  constructor(props: any) {
    super(props);
    this.state = {
      hasError: false,
      errorType: null,
      errorInfo: null,
    };
  }

  static getDerivedStateFromError(error: any) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  componentDidCatch(error: any, errorInfo: any) {
    // You can also log the error to an error reporting service
    this.setState({ errorType: error, errorInfo: errorInfo });
    // logErrorToMyService(error, errorInfo);
  }

  render() {
    if (this.state.hasError) {
      return (
        <div className="error-boundary-container">
          <h1>Rendering error</h1>
          {this.state.errorType && this.state.errorType.toString()}
          <br />
          <br />
          Details:{" "}
          <pre>
            {this.state.errorInfo &&
              JSON.stringify(this.state.errorInfo).replaceAll("\\n", "\n")}
          </pre>
        </div>
      );
    }

    return this.props.children;
  }
}

export default ErrorBoundary;
