import { useState, useEffect } from "react";
import { Button } from "primereact/button";
import { Card } from "primereact/card";
import { DataTable } from "primereact/datatable";
import { Dialog } from "primereact/dialog";
import { InputText } from "primereact/inputtext";
import { Column } from "primereact/column";
import { useAtom } from "jotai";
import { Customer } from "../interfaces";
import { MoneyValue } from "../components/MoneyValue";
import { customersAtom } from "../state";
import client from "../client";

export default function CustomersPage() {
  const [customers, setCustomers] = useAtom(customersAtom);
  const [selectedCustomer, setSelectedCustomer] = useState<Customer>();
  const [editDialogVisible, setEditDialogVisible] = useState<boolean>(false);

  function loadCustomers() {
    client.get("/api/v1/customer/").then((response) => {
      let received_customers: Customer[] = [];
      response.data.map((d: any) => {
        received_customers.push({
          id: d.id,
          name: d.name,
          rate: d.rate,
        });
      });
      setCustomers(received_customers);
    });
  }

  useEffect(() => {
    loadCustomers();
  }, []);

  function editCustomer(customer: Customer) {
    setSelectedCustomer(customer);
    setEditDialogVisible(true);
  }

  function save() {
    client.post("/api/v1/customer/edit/", selectedCustomer).then((response) => {
      loadCustomers();
      setEditDialogVisible(false);
    });
  }

  const nameTemplate = (customer: Customer) => (
    <Button link onClick={() => editCustomer(customer)}>
      {customer.name}
    </Button>
  );

  const rateTemplate = (customer: Customer) => {
    return <MoneyValue value={customer.rate} />;
  };

  return (
    <Card title="Customers">
      <Dialog
        header="Edit Customer"
        visible={editDialogVisible}
        onHide={() => setEditDialogVisible(false)}
      >
        <div className="flex flex-column gap-4">
          <InputText
            placeholder="Name"
            type="text"
            value={selectedCustomer?.name}
            onChange={(e) =>
              setSelectedCustomer({
                ...selectedCustomer,
                name: e.target.value,
              } as Customer)
            }
            autoFocus
          />
          <InputText
            placeholder="Rate"
            type="number"
            onChange={(e) => {
              if (selectedCustomer != null) {
                setSelectedCustomer({
                  ...selectedCustomer,
                  rate: {
                    ...selectedCustomer.rate,
                    amount: parseFloat(e.target.value),
                  },
                } as Customer);
              }
            }}
            value={selectedCustomer?.rate?.amount.toString()}
          />
          <div className="flex justify-content-evenly">
            <Button onClick={() => save()}>Save</Button>
            <Button onClick={() => setEditDialogVisible(false)}>Cancel</Button>
          </div>
        </div>
      </Dialog>

      <DataTable value={customers}>
        <Column header="Name" body={nameTemplate} />
        <Column header="Rate" body={rateTemplate} align="right" />
      </DataTable>
    </Card>
  );
}
